<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-alert variant="danger" show style="height: 50px; text-align: center;" v-if="$route.params.warning_show === true"><br> Silahkan lengkapi  NIP dan Asal Unit anda pada tab informasi.</b-alert>
    <!-- general tab -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Umum</span>
      </template>

      <account-setting-general
        @clicked="simpanData"
        :localData="localData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informasi</span>
      </template>

      <account-setting-information
        @clicked="simpanDataInformasi"
        :localData="localData"
        :kantorPenghubungOptions="kantorPenghubungOptions.options"
      />
    </b-tab>

    <!-- ubah password -->
    <b-tab
    >
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Ubah Password </span>
      </template>

      <account-setting-password
        :adminProcess="true"
      />
    </b-tab>

    <b-tab
    v-if="$can('manage','all')"
    >
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Hak Akses </span>
      </template>

      <rules
        :adminProcess="true"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BAlert, } from 'bootstrap-vue'
import {
  insUser,
  ubahUser,
  uploadFileUser,
  getUser,
} from '@/api/user'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import Rules from './Rules.vue'
import {
  getKantorPenghubung,
} from '@/connection-api/master'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingInformation,
    AccountSettingPassword,
    Rules,
    BAlert,
  },
  data() {
    return {
      localData: {},
      options: {},
      permissionChangePassword: false,
       kantorPenghubungOptions: {
        selected: 'id',
        options: [
          {value: null, label: '--Pilih Kantor Penghubung--'},
        ],
       },
    }
  },
  async mounted() {
    if(this.$route.params.id)
    {
      const response = await getUser(this.$route.params.id)
      this.localData = response.data.data
      this.localData.provinsi_id = parseInt(this.localData.provinsi_id)
      this.localData.kabkota_id = parseInt(this.localData.kabkota_id)
    }else{
      this.$router.push({name: 'dashboard'})
    }
    const responseKantorPenghubung = await getKantorPenghubung()
    this.mappingSelectOption(responseKantorPenghubung.data, this.kantorPenghubungOptions.options)
  },
  methods: {
    mappingSelectOption(master, name){
      if(master.length > 0){
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async simpanDataSocial(value) {
      // console
      value.form = 'social'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Informasi Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      }
    },
    async simpanDataInformasi(value) {
      // console
      value.form = 'informasi'
      if (value.id) {
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah Informasi Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      }
    },
    async simpanData(value) {
      let imagefilename = ''
      if (value.File) {
        // Update File
        const responseUpload = await uploadFileUser(value.File, 'user', event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        imagefilename = responseUpload.data.filename
      }
      if (value.id) {
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        const response = await ubahUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Ubah User Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      } else {
        if (value.File) {
          value.img = imagefilename
          delete value.File
        }
        // console.log(value)
        const response = await insUser(value)
        if (response.data.status.code === '00') {
          this.makeToast('success', 'Tambah User Berhasil!')
        } else {
          this.makeToast('danger', response.data.data)
          this.show = false
        }
      }
    },
    makeToast(variant = null, message = null, title = null) {
      this.$bvToast.toast(message, {
        title: 'Kegiatan',
        variant,
        solid: true,
        autoHideDelay: 10000,
        enableHtml: true,
        allowHtml: true,
      })
    },
  },
}
</script>
