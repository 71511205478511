<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col sm="12">
          <b-form-group
            label="Alamat"
            label-for="account-alamat"
          >
            <b-form-input
              name="alamat"
              placeholder="Alamat"
              v-model="localData.alamat"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="provinsiList"
            label="Provinsi"
          >
            <v-select
              id="provinsiList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="provinsiOption.options"
              :reduce="val => val.value"
              @input="(val) => changeKabKota(val)"
              v-model="localData.provinsi_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="kabKotaList"
            label="Kabupaten / Kota"
          >
            <v-select
              id="kabKotaList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="kabkotaOption.options"
              :reduce="val => val.value"
              v-model="localData.kabkota_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="phone"
            label="HP / Telepon"
          >
            <b-form-input
              name="telepon"
              placeholder="telepon"
              v-model="localData.nomor_hp"
              :type="'number'"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="userData.hak_akses === 'Superadmin' || userData.hak_akses === 'Admin-unit-utama' || userData.hak_akses === 'Admin-kementerian'">
          <b-form-group
            label-for="nip"
            label="NIP"
          >
            <b-form-input
              name="nip"
              placeholder="nip"
              v-model="localData.nip"
              :type="'number'"
            />
          </b-form-group>
        </b-col>
       
         
        <b-col md="6">
          <b-form-group
            label-for="Kantor Penghubung"
            label="Kantor Penghubung"
          >
             <v-select
              id="kantor_penghubung"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="val => val.value"
              :options="kantorPenghubungOptions"
              v-model="localData.kantor_penghubung_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea,
} from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import {
  getProvinsi,
  getKota,
} from '@/connection-api/master'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
    localData: {},
    kantorPenghubungOptions: {},
  },
  data() {
    return {
      provinsiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kabkotaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kabupaten / Kota --' },
        ],
      },
      pendidikanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Pendidikan Terakhir --' },
        ],
      },
      statusPekerjaanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Pekerjaan --' },
        ],
      },
      statusPernikahanOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Status Pernikahan --' },
        ],
      },
      unitUtamaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Unit Utama --' },
        ],
      },
      instansiMitraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Instansi/Mitra --' },
        ],
      },
      clevePhone: {
        phone: true,
        phoneRegionCode: 'ID',
      },
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  async mounted() {
    const responsePropinsi = await getProvinsi()
    this.mappingSelectOption(responsePropinsi.data, this.provinsiOption.options)

    // const responseUnitUtama = await getUnitUtama()
    // this.mappingSelectOption(responseUnitUtama.data, this.unitUtamaOptions.options)

    // const responseMitra = await getInstansi()
    // this.mappingSelectOption(responseMitra.data, this.instansiMitraOptions.options)

    if(this.localData.provinsi_id)
    {
      this.changeKabKota(this.localData.provinsi_id)
    }
  },
  methods: {
    async changeKabKota(propinsiId) {
      if(propinsiId !== null)
      {
        this.kabkotaOption = {
          selected: 'id',
          options: [
            { value: null, label: '--Pilih Kabupaten Kota --' },
          ],
        }
        const response = await getKota({ provinsi_id: propinsiId })
        this.mappingSelectOption(response.data, this.kabkotaOption.options)
      }
      
    },
    simpan() {
      this.localData.id = this.$route.params.id
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.localData = {}
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
