<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-img
          v-if="localData.avatar"
          ref="previewEl"
          rounded
          :src="localData.avatar"
          height="80"
        />
        <b-img
          v-else
          ref="previewEl"
          rounded
          :src="avatar"
          width="125"
        />
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="localData.File"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="onFileChange"
        />
        <!--/ reset -->
        <b-card-text>Foto JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-name"
          >
            <b-form-input
              v-model="localData.nama_lengkap"
              name="name"
              placeholder="Nama Lengkap"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="example-datepicker"
            label="Tanggal Lahir"
          >
            <flat-pickr
              v-model="localData.tanggal_lahir"
              class="form-control"
              name="date"
              placeholder="Tanggal Lahir"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="localData.email"
              name="email"
              placeholder="Email"
              :readonly="true"
            />

          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="genderList"
            label="Jenis Kelamin"
          >
             <v-select
                v-model="localData.gender_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
                :reduce="val => val.value"
                :options="genderOption.options"
              />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="simpan"
          >
            Simpan
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import {
  getJenisKelamin,
} from '@/connection-api/master'
import {
  downloadFile,
} from '@/api/user'
import flatPickr from 'vue-flatpickr-component'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    // BLink,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    localData: {},
  },
  data() {
    return {
      genderOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kelamin --' },
        ],
      },
      disabilitasOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Disabilitas --' },
        ],
      },
      profileFile: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  async mounted() {
    const responseJenisKelamin = await getJenisKelamin()
    this.mappingSelectOption(responseJenisKelamin.data, this.genderOption.options)
  },
  methods: {
    simpan() {
      this.localData.id = this.$route.params.id
      this.$emit('clicked', this.localData)
    },
    resetForm() {
      this.localData = {}
    },
    onFileChange(e) {
      const file = e// .target.files[0]
      this.localData.avatar = URL.createObjectURL(file)
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
